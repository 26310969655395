import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

export const FadeUpEachWhenVisible = ({
    children,
    toY,
    fromY,
    index,
    delayTo,
    delayFrom,
    duration
}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            custom={index}
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: (index) => ({
                    opacity: 1,
                    y: toY,
                    transition: {
                        duration: duration,
                        delay: index * delayTo
                    }
                }),
                hidden: (index) => ({
                    opacity: 0,
                    y: fromY,
                    transition: {
                        duration: duration,
                        delay: delayFrom * index
                    }
                })
            }}>
            {children}
        </motion.div>
    );
};
