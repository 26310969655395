import React from 'react';
import useToggle from '../../hooks/utils/useToggle';
import { motion, AnimatePresence } from 'framer-motion';

function ReadMore({ children }) {
    const text = children;
    const [isReadMore, setIsReadMore] = useToggle(true);

    return (
        <div className="more-text">
            <AnimatePresence>
                {text.length > 200 ? (
                    <>
                        {isReadMore ? (
                            <motion.p
                                key="1"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>{`${text.slice(0, 190)}...`}</motion.p>
                        ) : (
                            <motion.p
                                key="2"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>
                                {text}
                            </motion.p>
                        )}
                        <span onClick={setIsReadMore} className="read-or-hide">
                            {isReadMore ? 'Read More' : ' Show Less'}
                        </span>
                    </>
                ) : isReadMore ? (
                    <motion.p
                        key="3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>{`${text.slice(0, 190)}...`}</motion.p>
                ) : (
                    <motion.p
                        key="4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        {text}
                    </motion.p>
                )}
            </AnimatePresence>
        </div>
    );
}

export default ReadMore;
