import useContentful from '../api/useContentful';

export default function useNews() {
    const { data, isLoading } = useContentful('UBjTu0KlJm9wXKDfDdEO7');

    const title = data?.title || '';
    const items = data?.items || [];
    
    return {
        title,
        items,
        isLoading
    };
}
