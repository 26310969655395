import useContentful from '../api/useContentful';

export default function useHeader() {
    const { data, isLoading } = useContentful('3Q1T2vW6HF6JUPD06ReloJ');
    const links = data?.links || [];
    const socialItems = data?.socialItems || [];

    return {
        links,
        socialItems,
        isLoading
    };
}
