import React from 'react';
import Cursor from '../components/cursor/cursor';
import { Eye } from './svgIcons';

function ArrowLeft({ disabled, onClick, color }) {
    const disabeld = disabled ? ' arrow--disabled' : '';
    return (
        <div className={'arrow-wrapper arrow-wrapper-left'} onClick={onClick}>
            <svg
                className={'arrow arrow--left arrow-desk' + disabeld}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 29 19">
                <g fill={color} fillRule="evenodd">
                    <g fill={color}>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M0 7.571H26V10.571H0z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11)"
                                        />
                                        <path
                                            d="M16.317 4.164H29.072000000000003V7.164H16.317z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(45 22.695 5.664)"
                                        />
                                        <path
                                            d="M16.317 11.069H29.072000000000003V14.069H16.317z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(-45 22.695 12.569)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <svg
                className={'arrow arrow--left arrow-mob' + disabeld}
                width="58"
                height="28"
                xmlns="http://www.w3.org/2000/svg">
                <g fill="#DB0400" fillRule="evenodd">
                    <path d="M43.997.851 57.37 14.225l-1.783 1.783L42.214 2.635z" />
                    <path d="m42.26 25.83 13.374-13.373 1.783 1.783-13.373 13.373z" />
                    <path d="M.96 13.081H55.7v2.522H.96z" />
                </g>
            </svg>
        </div>
    );
}

function ArrowRight({ disabled, onClick, color }) {
    const disabeld = disabled ? ' arrow--disabled' : '';
    return (
        <div className={'arrow-wrapper arrow-wrapper-right'} onClick={onClick}>
            <svg
                className={'arrow arrow--right arrow-desk' + disabeld}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 29 19">
                <g fill={color} fillRule="evenodd">
                    <g fill={color}>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M0 7.571H26V10.571H0z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11)"
                                        />
                                        <path
                                            d="M16.317 4.164H29.072000000000003V7.164H16.317z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(45 22.695 5.664)"
                                        />
                                        <path
                                            d="M16.317 11.069H29.072000000000003V14.069H16.317z"
                                            transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(-45 22.695 12.569)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <svg
                className={'arrow arrow--right arrow-mob' + disabeld}
                width="58"
                height="28"
                xmlns="http://www.w3.org/2000/svg">
                <g fill="#DB0400" fillRule="evenodd">
                    <path d="M43.997.851 57.37 14.225l-1.783 1.783L42.214 2.635z" />
                    <path d="m42.26 25.83 13.374-13.373 1.783 1.783-13.373 13.373z" />
                    <path d="M.96 13.081H55.7v2.522H.96z" />
                </g>
            </svg>
        </div>
    );
}

export const Navigator = ({
    currentSlide,
    slider,
    color,
    showArrowLeft,
    showArrowRight,
    cursorMove,
    showEyesIcon
}) => {
    return (
        <div className={'arrows noselect'}>
            {true && (
                <ArrowLeft
                    onClick={(e) => e.stopPropagation() || slider.prev()}
                    disabled={currentSlide === 0}
                    color={color}
                />
            )}
            &nbsp;&nbsp;&nbsp;
            {true && (
                <ArrowRight
                    onClick={(e) => e.stopPropagation() || slider.next()}
                    disabled={currentSlide === slider.details().size - 1}
                    color={color}
                />
            )}
            {/* <Cursor cursorMove={cursorMove}>
               {showArrowLeft && (
                    <div className={'arrow-wrapper arrow-wrapper-left'}>
                        <ArrowLeft
                            onClick={(e) => e.stopPropagation() || slider.prev()}
                            disabled={currentSlide === 0}
                            color={color}
                        />
                    </div>
                )}
                {showArrowRight && (
                    <div className={'arrow-wrapper arrow-wrapper-right'}>
                        <ArrowRight
                            onClick={(e) => e.stopPropagation() || slider.next()}
                            disabled={currentSlide === slider.details().size - 1}
                            color={color}
                        />
                    </div>
                )}

                {showEyesIcon && (
                    <div className={'hidden-icon flex'}>
                        <Eye color={'#fff'} />
                    </div>
                )}
            </Cursor> */}
        </div>
    );
};

export const Dots = ({ currentSlide, slider }) => {
    return (
        <div className="dots">
            {[...Array(slider.details().size).keys()].map((idx) => {
                return (
                    <button
                        key={idx}
                        onClick={() => {
                            slider.moveToSlideRelative(idx);
                        }}
                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                    />
                );
            })}
        </div>
    );
};
