import React from 'react';
import { AnimateLoader } from '../../utils/animateLoader';
import { motion } from 'framer-motion';
import { Ten35Logo } from '../../utils/svgIcons';

function ProgressLoader({ delayLogo }) {
    return (
        <div className={'columns'}>
            <AnimateLoader />
            <div className={'animated-logo'}>
                <motion.div
                    initial={{ opacity: 0, scale: 1.2 }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        transition: { delay: delayLogo, duration: 0.3 }
                    }}>
                    <Ten35Logo color={'#fff'} />
                </motion.div>
            </div>
        </div>
    );
}

export default ProgressLoader;
