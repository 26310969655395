import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const ScaleWhenVisible = ({
    children,
    clipPathTo,
    clipPathFrom,
    delayTo,
    delayFrom,
    position,
    bottom,
    zIndex
}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    //up-down
    // clipPathTo = { 'inset(0% 0% 0% 0%)'}
    // clipPathFrom = { 'inset(0% 0% 100% 0%)'}

    //down-up
    // clipPathTo = { 'inset(0% 0% 0 0%)'}
    // clipPathFrom = { 'inset(100% 0% 0 0%)'}

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: {
                    opacity: 1,
                    zIndex: zIndex,
                    position: position,
                    bottom: bottom,
                    clipPath: clipPathTo,
                    transition: {
                        duration: 1,
                        delay: delayTo
                    }
                },
                hidden: {
                    opacity: 0,
                    clipPath: clipPathFrom,
                    transition: {
                        duration: 1,
                        delay: delayFrom
                    }
                }
            }}>
            {children}
        </motion.div>
    );
};

export const ShowWhenVisible = ({
    translateTo,
    translateFrom,
    delayTo,
    delayFrom,
    position,
    top,
    left,
    bottom,
    zIndex,
    background
}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: {
                    y: translateTo,
                    transition: {
                        duration: 1,
                        delay: delayTo
                    }
                },
                hidden: {
                    position: position,
                    top: top,
                    left: left,
                    bottom: bottom,
                    y: translateFrom,
                    zIndex: zIndex,
                    backgroundColor: background,
                    width: '100%',
                    height: '100%',
                    transition: {
                        duration: 1,
                        delay: delayFrom
                    }
                }
            }}
        />
    );
};
