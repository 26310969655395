import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

export const MoveWhenVisible = ({ children, fromX, toX, fromY, toY, delayFrom, delayTo }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: {
                    opacity: 1,
                    x: fromX,
                    y: fromY,
                    transition: {
                        duration: 1.2,
                        delay: delayFrom
                    }
                },
                hidden: {
                    opacity: 0,
                    x: toX,
                    y: toY,
                    transition: {
                        duration: 1.2,
                        delay: delayTo
                    }
                }
            }}>
            {children}
        </motion.div>
    );
};
