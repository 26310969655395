import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';

//Containers
import TopperContainer from '../hero';
import WorkContainer from '../work';
import TeamContainer from '../team';
import NewsContainer from '../news';
import ClientsContainer from '../clients';

// Hooks
import useHomeMeta from '../../hooks/ui/useHomeMeta';
import { NavContext } from '../../utils/navContext';
import { Ten35Logo } from "../../utils/svgIcons";
import { motion } from "framer-motion";

const Footer = React.lazy(() => import('../../components/footer'));

export const standardLoaderAnimate = {
  visible: {
    opacity: [1, 1, 0],
    zIndex: 1,
    height: ['100vh', '100vh', '100vh', '0vh'],
    transition: { duration: .8 }
  },
  hidden: {
    opacity: 1,
  }
};


function HomeView() {
    const containerRef = useRef(null);
    const { title, image, description } = useHomeMeta();

    const [isInView, setIsInView] = useState('');
    const [isModalShow, setIsModalShow] = useState(false);

    const contextValue = {
        isInView,
        setIsInView,
        isModalShow,
        setIsModalShow
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image.url} />
            </Helmet>
            <LocomotiveScrollProvider
                options={{
                    smooth: true,
                    scrollFromAnywhere: true,
                    multiplier: 0.85,
                    lerp: 0.04,
                    tablet: { smooth: true, multiplier: 0.55 },
                    smartphone: { smooth: false }
                }}
                watch={[]}
                containerRef={containerRef}>
                <div className={'home-container'} data-scroll-container ref={containerRef}>
                  <motion.div
                    className={'standard-loader'}
                    variants={standardLoaderAnimate}
                    initial="hidden"
                    animate="visible">
                    <Ten35Logo color={'#fff'} />
                  </motion.div>
                  <NavContext.Provider value={contextValue}>
                        <TopperContainer />
                        <WorkContainer />
                        <TeamContainer />
                        <ClientsContainer />
                        <NewsContainer />
                        <Footer />
                    </NavContext.Provider>
                </div>
            </LocomotiveScrollProvider>
        </>
    );
}

export default HomeView;
