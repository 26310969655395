import useContentful from '../api/useContentful';

export default function useTeam() {
    const { data, isLoading } = useContentful('5wLQSZnV6fO2cNogU1fCSN');

    const title = data?.title || '';
    const percentBlock = data?.percentBlock || [];
    // const carouselItems = data?.carouselItems.filter((item) => item.fields) || [];

    return {
        title,
        percentBlock,
        // carouselItems,
        isLoading
    };
}
