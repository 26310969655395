import React, { useEffect, useRef } from 'react';
import { animate } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Counter({ from, to }) {
    const nodeRef = useRef();
    const [ref, inView] = useInView({
        triggerOnce: true
    });

    useEffect(() => {
        const node = nodeRef.current;

        if (inView) {
            const controls = animate(from, to, {
                duration: 2.5,
                onUpdate(value) {
                    node.textContent = value.toFixed();
                }
            });
            return () => controls.stop();
        }
    }, [from, to, inView]);

    return (
        <div ref={ref}>
            <p ref={nodeRef} />
        </div>
    );
}

export default function AnimatedCounter({ from, to }) {
    return <Counter from={from} to={to} />;
}
