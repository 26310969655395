import React, { useEffect, useState } from 'react';

// Hooks
import useClients from '../../hooks/ui/useClients';
import { MoveWhenVisible } from '../../utils/moveWhenVisible';
import { FadeUpEachWhenVisible } from '../../utils/fadeUpEachWhenVisible';

function Images({ clientsImage }) {
    return (
        <ul className={'clients-tiles'}>
            {clientsImage.map((item, index) => {
                return (
                    item.fields && (
                        <li className={'tile-item'} key={index}>
                            <FadeUpEachWhenVisible
                                index={index}
                                toY={0}
                                fromY={-20}
                                delayTo={0.08}
                                delayFrom={0.08}
                                duration={0.2}>
                                <img
                                    src={`${item.fields.image.fields.file.url}`}
                                    alt={`${item.fields.image.fields.title}`}
                                />
                            </FadeUpEachWhenVisible>
                        </li>
                    )
                );
            })}
        </ul>
    );
}

function ClientsView() {
    const { title, image, clientsImage } = useClients();
    const [isClients, setIsClients] = useState({});

    useEffect(() => {
        if (clientsImage.length) {
            setIsClients(clientsImage);
        }
    }, [clientsImage, isClients]);

    return (
        <section
            id={'clients'}
            className={'clients'}
            style={{ backgroundImage: `url(${image.url})` }}
            data-scroll-section>
            <MoveWhenVisible fromX={0} toX={20} delayFrom={0.2} delayTo={0.2}>
                <p className="clients-title">{title}</p>
            </MoveWhenVisible>
            {isClients.length && <Images clientsImage={isClients} />}
        </section>
    );
}

export default ClientsView;
