import useContentful from '../api/useContentful';

export default function useClients() {
    const { data, isLoading } = useContentful('7dOycBNzmsdiFpRhid8KXs');

    const title = data?.title || '';
    const clientsImage = data?.clientsImage || [];
    const image = data?.image.fields?.file || '';

    return { title, image, clientsImage, isLoading };
}
