import useContentful from '../api/useContentful';

export default function useHero() {
    const { data, isLoading } = useContentful('2QMIEBVyrvouiu6OVyJPA2');

    const about = data?.about || '';
    const title = data?.title || '';
    const backgroundVideo = data?.backgroundVideo.fields || {};

    return {
        about,
        title,
        backgroundVideo,
        isLoading
    };
}
