import React from 'react';
import HomeView from './view';

function HomeContainer() {
    return (
      <HomeView />
    );
}

export default HomeContainer;
