import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

export const ScaleEachWhenVisible = ({
    children,
    clipPathTo,
    clipPathFrom,
    delayTo,
    delayFrom,
    index
}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            if (index >= 3) {
                controls.start('visibleThree');
            } else {
                controls.start('visible');
            }
        }
    }, [controls, inView, index]);

    return (
        <AnimatePresence>
            <motion.div
                custom={index}
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                    visible: (index) => ({
                        opacity: 1,
                        clipPath: clipPathTo,
                        transition: {
                            duration: 0.8,
                            delay: index * delayTo
                        }
                    }),
                    visibleThree: () => ({
                        opacity: 1,
                        clipPath: clipPathTo
                    }),
                    hidden: (index) => ({
                        opacity: 0,
                        clipPath: clipPathFrom,
                        transition: {
                            delay: delayFrom * index
                        }
                    })
                }}>
                {children}
            </motion.div>
        </AnimatePresence>
    );
};

export const ShowEachWhenVisible = ({
    children,
    translateTo,
    translateFrom,
    delayTo,
    delayFrom,
    position,
    zIndex,
    background,
    index
}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            if (index >= 3) {
                controls.start('visibleThree');
            } else {
                controls.start('visible');
            }
        }
    }, [controls, inView, index]);

    return (
        <AnimatePresence>
            <motion.div
                custom={index}
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                    visible: (index) => ({
                        y: translateTo,
                        transition: {
                            duration: 0.8,
                            delay: index * delayTo
                        }
                    }),
                    visibleThree: () => ({
                        y: translateTo
                    }),
                    hidden: (index) => ({
                        position: position,
                        top: 0,
                        left: 0,
                        y: translateFrom,
                        zIndex: zIndex,
                        backgroundColor: background,
                        width: '100%',
                        height: '100%',
                        transition: {
                            delay: delayFrom * index
                        }
                    })
                }}>
                {children}
            </motion.div>
        </AnimatePresence>
    );
};
