import React, { useEffect, useState, useContext } from 'react';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useInView } from 'react-intersection-observer';

// Components
import Modal from '../../components/modal';
import Button from '../../components/button';

// Hooks
import useTeam from '../../hooks/ui/useTeam';

// Utils
import { Navigator } from '../../utils/carouselControllsTeam';
import { FadeUpEachWhenVisible } from '../../utils/fadeUpEachWhenVisible';
import { MoveWhenVisible } from '../../utils/moveWhenVisible';
import AnimatedCounter from '../../utils/animateCounter';
import { NavContext } from '../../utils/navContext';
import useTeamPage from '../../hooks/ui/useTeamPage';
import { Helmet } from "react-helmet";

export const teamAnimate = {
    visible: {
        opacity: 1,
        transition: { delay: 0.5, duration: 0.6 }
    },
    hidden: {
        opacity: 0
    },
    exit: {
        opacity: 0
    }
};

function Slider({ carouselItems, setEmployeeData, onCycle }) {
    const [options, setOptions] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider(options);

    useEffect(() => {
        if (carouselItems.length) {
            setOptions({
                initial: 1,
                spacing: 20,
                centered: true,
                loop: true,
                slidesPerView: 3,
                slideChanged(s) {
                    setCurrentSlide(s.details().relativeSlide);
                },
                breakpoints: {
                    '(max-width: 1200px)': {
                        spacing: 140,
                        slidesPerView: 2
                    },
                    '(max-width: 800px)': {
                        slidesPerView: 2
                    },
                    '(max-width: 600px)': {
                        slidesPerView: 1,
                        spacing: 20,
                        centered: true
                    },
                    '(max-width: 480px)': {
                        slidesPerView: 1.15,
                        spacing: 50,
                        centered: true
                    },
                    '(max-width: 428px)': {
                        slidesPerView: 1.01,
                        spacing: 20,
                        centered: true
                    }
                }
            });
            slider && slider.refresh();
        }
    }, [slider, carouselItems]);

    return (
        <motion.div
            data-scroll
            className={'team-items'}
            variants={teamAnimate}
            initial={'hidden'}
            animate={'visible'}
            exit={'exit'}>
            <div ref={sliderRef} className={'keen-slider'}>
                {carouselItems.map((item, index) => {
                    const size = 3;
                    let worksList = [];
                    if (item.fields) {
                        worksList = item.fields.works.slice(0, size);
                    }
                    return (
                        item.fields && (
                            <div
                                key={index}
                                onClick={() => setEmployeeData(carouselItems, onCycle, index)}
                                className="keen-slider__slide">
                                <div
                                    className={
                                        currentSlide === index
                                            ? 'keen-slider__slide-wrapper show-bg show-selected'
                                            : 'keen-slider__slide-wrapper show-bg'
                                    }>
                                    <img
                                        className="keen-slider__slide-img"
                                        src={item.fields.image.fields.file.url}
                                        alt={item.fields.image.fields.title}
                                    />
                                    <div className={'hidden-item flex'}>
                                        {worksList.map((img, index) => {
                                            return (
                                                <div
                                                    className={'hidden-item__img-preview'}
                                                    key={index}>
                                                    <img
                                                        src={img.fields.image.fields.file.url}
                                                        alt={img.fields.image.fields.title}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <div className={'hidden-item__about'}>
                                            <p className={'hidden-item__position'}>
                                                {item.fields.position}
                                            </p>
                                            <p className={'hidden-item__name'}>
                                                {item.fields.title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="keen-slider__slide-name">{item.fields.title}</p>
                            </div>
                        )
                    );
                })}
            </div>
            {slider && <Navigator slider={slider} currentSlide={currentSlide} color={'#fff'} />}
            <Button url={'/team'} text={'View Team'} mod={'team'} />
        </motion.div>
    );
}

function TeamView() {
    const { scroll } = useLocomotiveScroll();
    const [employee, setEmployee] = useState(null);

    const [refNav, inView] = useInView({ threshold: 0.2 });
    const { setIsInView } = useContext(NavContext);

    // Slider state
    const [isVisible, onCycle] = useCycle(true, false);
    const [index, setIndex] = useState(0);

    // Cms data
    const { employeesListSection } = useTeamPage();
    const { title, percentBlock } = useTeam();

    useEffect(() => {
        if (inView) {
            setIsInView('team');
        }
    }, [inView, setIsInView]);

    useEffect(() => {
        if (!isVisible && scroll) {
            scroll.stop();
        }
        if (scroll && isVisible) {
            scroll && scroll.start();
        }
    }, [isVisible, scroll]);

    const setEmployeeData = (data, onCycle, index) => {
        setEmployee(data);
        setIndex(index);
        onCycle(onCycle);
    };

    return (
        <>
            <section className={'team'} data-scroll-section>
                <div className={'team-about flex'} data-scroll ref={refNav}>
                    <MoveWhenVisible fromX={0} toX={20} delayFrom={0.2} delayTo={0.2}>
                        <p className={'line-text'} id="team">
                            {title}
                        </p>
                    </MoveWhenVisible>
                    <ul className={'numbers'} data-scroll>
                        {percentBlock.map((num, index) => {
                            const numberSting = num.fields.percentage.slice(0, 2);
                            const number = parseInt(numberSting, 10);
                            return (
                                <FadeUpEachWhenVisible
                                    index={index}
                                    toY={0}
                                    fromY={20}
                                    delayTo={0.5}
                                    delayFrom={0.5}
                                    duration={0.8}
                                    key={index}>
                                    <li>
                                        <div className={'percent'}>
                                            <AnimatedCounter from={0} to={number} />
                                            {num.fields.specialCharacter}
                                        </div>
                                        <p>{num.fields.title}</p>
                                    </li>
                                </FadeUpEachWhenVisible>
                            );
                        })}
                    </ul>
                </div>
                <AnimatePresence>
                    <Slider
                        onCycle={onCycle}
                        carouselItems={employeesListSection}
                        setEmployeeData={setEmployeeData}
                    />
                </AnimatePresence>
            </section>
            <AnimatePresence>
                {!isVisible && (
                    <Modal
                        onCycle={onCycle}
                        employee={employee}
                        index={index}
                        link={'/'}
                        isVisible={isVisible}
                    />
                )}
            </AnimatePresence>
        </>
    );
}

export default TeamView;
