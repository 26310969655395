import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

const column = [
    <div className={'column'} />,
    <div className={'column'} />,
    <div className={'column'} />,
    <div className={'column'} />
];

export const AnimateLoader = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const ease = [0.6, 0.05, -0.01, 0.99];

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return column.map((div, index) => {
        return (
            <motion.div
                key={index}
                custom={index}
                ref={ref}
                animate={controls}
                initial="hidden"
                exit="hidden"
                variants={{
                    visible: (index) => ({
                        y: '0%',
                        transition: {
                            ease: ease,
                            duration: 0.2,
                            delay: index * 0.2
                        }
                    }),
                    hidden: (index) => ({
                        y: '100%',
                        transition: {
                            ease: ease,
                            duration: 0.2,
                            delay: 0.2 * index
                        }
                    })
                }}>
                {div}
            </motion.div>
        );
    });
};
