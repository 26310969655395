import useContentful from '../api/useContentful';

export default function useWorks() {
    const { data, isLoading } = useContentful('1BDjQSpA3kMObyZOWNlszo');

    const items = data?.worksList.filter((item) => item.fields) || [];
    const title = data?.title || '';

    return {
        items,
        title,
        isLoading
    };
}
