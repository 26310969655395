import React from 'react';
import ReactGA from 'react-ga';

const useGAEventsTrack = (category = 'Pages') => {
    const trackEvent = (action = 'action', label = 'label') => {
        ReactGA.event({
            category,
            action,
            label
        });
    };
    return trackEvent;
};

export default useGAEventsTrack;
