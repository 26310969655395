import React, { useEffect, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Utils
import { NavContext } from '../../utils/navContext';

// Components
import Video from '../../components/video';
import Header from '../../components/header';
import Button from '../../components/button';

// Hooks
import useRichText from '../../hooks/api/useRichText';
import useHero from '../../hooks/ui/useHero';
import { ShowWhenVisible } from '../../utils/scaleWhenVisible';
import { useWorkAnimation } from '../../hooks/ui/useWorkAnimation';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

const videoAnimate = {
    visible: {
        clipPath: 'inset(100% 0 0 0)',
        transition: { duration: 0.8, delay: 0.2 }
    },
    hidden: {
        clipPath: 'inset(0 0 0 0)',
        transition: { duration: 0.8, delay: 0.2 }
    }
};

function TopperView() {
    const { about, backgroundVideo } = useHero();
    const [refNav, inView] = useInView({ threshold: 0.8 });
    const { setIsInView } = useContext(NavContext);
    const { homeAnchor } = useWorkAnimation();
    const { scroll } = useLocomotiveScroll();
    
    const scrollTo = (target) => {
        scroll &&
            scroll.scrollTo(`#${target}`, {
                offset: -130,
                duration: 800,
                easing: [0.25, 0.0, 0.35, 1.0],
                disableLerp: true
            });
    };

    // Scroll to the work section, after returning to the home page
    useEffect(() => {
        if (scroll && homeAnchor) {
            scrollTo(homeAnchor);
        }
    }, [homeAnchor, scroll]);

    useEffect(() => {
        if (inView) {
            setIsInView('about');
        }
    }, [inView, setIsInView]);

    const { parseBodyText } = useRichText();
    const {
        thumbnailDesktop,
        thumbnailMobile,
        fullVideoDesktop,
        fullVideoMobile,
        videoLoopDesktop,
        videoLoopMobile,
        showScrollButton,
        scrollToElement
    } = backgroundVideo;

    return (
        <AnimatePresence exitBeforeEnter={false}>
            <Header key={'header'} />
            <section className={'topper'} id={'hero'} data-scroll-section>
                <div className={'topper-head'}>
                    <motion.div
                        className={'video-shape'}
                        variants={videoAnimate}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    />
                    <Video
                        showScrollButton={showScrollButton}
                        toElement={scrollToElement}
                        thumbnailDesktop={thumbnailDesktop ? thumbnailDesktop.fields.file.url : ''}
                        thumbnailMobile={thumbnailMobile ? thumbnailMobile.fields.file.url : ''}
                        fullVideoDesktop={fullVideoDesktop}
                        fullVideoMobile={fullVideoMobile}
                        loopVideoDesktop={videoLoopDesktop ? videoLoopDesktop.fields.file.url : ''}
                        loopVideoMobile={videoLoopMobile ? videoLoopMobile.fields.file.url : ''}
                    />
                    <div className={'topper-about'} id="about" data-scroll ref={refNav}>
                        <div className={'topper-about_text'}>
                            <ShowWhenVisible
                                translateFrom={'0%'}
                                translateTo={'100%'}
                                background={'white'}
                                position={'absolute'}
                                zIndex={'1'}
                                delayTo={0.8}
                                delayFrom={0.8}
                            />
                            {parseBodyText(about)}
                        </div>
                    </div>
                </div>
            </section>
        </AnimatePresence>
    );
}

export default TopperView;
