import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useInView } from 'react-intersection-observer';

//components
import Button from '../../components/button';

// Hooks
import useWorks from '../../hooks/ui/useWorks';
import { useWorkAnimation } from '../../hooks/ui/useWorkAnimation';
import useGAEventsTrack from '../../hooks/utils/useGAEventsTrack';

// Utils
import { Arrow } from '../../utils/svgIcons';
import { ShowWhenVisible, ScaleWhenVisible } from '../../utils/scaleWhenVisible';
import { MoveWhenVisible } from '../../utils/moveWhenVisible';
import { NavContext } from '../../utils/navContext';

function WorkView() {
    const { location } = useHistory();
    const { items, title } = useWorks();
    const [refNav, inView] = useInView({ threshold: 0.15 });
    const { setIsInView } = useContext(NavContext);
    const { onWorkItemClick } = useWorkAnimation();

    useEffect(() => {
        if (inView) {
            setIsInView('work');
        }
    }, [inView, setIsInView]);

    const GATracker = useGAEventsTrack('Work Pages');

    return (
        <section className={'works'} data-scroll-section id="work" ref={refNav}>
            <p className={'line-text'}>{title}</p>
            <div className={'works-items'} data-scroll>
                {items.slice(0, 5).map((item, index) => {
                    return (
                        item.fields && (
                            <div
                                className={'works-item'}
                                key={index}
                                onClick={() => onWorkItemClick(`home/work/${item.fields.slug}`)}>
                                <Link to={{pathname: `/work/${item.fields.slug}`, state: { prevPath: location.pathname }}}>
                                    <div className="frame">
                                        <ShowWhenVisible
                                            translateFrom={'0%'}
                                            translateTo={'-100%'}
                                            position={'absolute'}
                                            delayTo={0.15}
                                            zIndex={'10'}
                                            background={'white'}
                                        />
                                        <ScaleWhenVisible delayTo={0.1} delayFrom={0.1}>
                                            <img
                                                className="bg-image"
                                                src={item.fields.image.fields.file.url}
                                                alt={item.fields.image.fields.title}
                                            />
                                        </ScaleWhenVisible>
                                        <div className="wedge"></div>
                                        <div className="logo">
                                            {item.fields.logo && (
                                                <img
                                                    src={item.fields.logo.fields.file.url}
                                                    alt={''}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Link>
                                <div className={'works-about flex'}>
                                    <MoveWhenVisible
                                        fromX={0}
                                        toX={20}
                                        delayFrom={0.2}
                                        delayTo={0.2}>
                                        <div className={'texts'}>
                                            <h3>{item.fields.title}</h3>
                                            <p>{item.fields.description}</p>
                                        </div>
                                    </MoveWhenVisible>
                                    <div className="view">
                                        <MoveWhenVisible
                                            fromX={20}
                                            toX={0}
                                            delayFrom={0.2}
                                            delayTo={0.2}>
                                            <Link to={{pathname: `/work/${item.fields.slug}`, state: { prevPath: location.pathname }}}>
                                                <p>{item.fields.viewText}</p>
                                                <span>
                                                    <Arrow color={'#ff0000'} />
                                                </span>
                                            </Link>
                                        </MoveWhenVisible>
                                    </div>
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
            <Button url={'/work'} text={'View All'} mod={'work-list'} />
        </section>
    );
}

export default WorkView;
