import { createClient } from 'contentful';

const previewURLs = ['localhost', 'ten35-dev', 'ten35-staging'];
let usePreviewAPI = false;
let client;

for (let url of previewURLs) {
    if (document.location.href.includes(url)) {
        usePreviewAPI = true;
    }
}

if (usePreviewAPI) {
    // Use Preview API
    client = createClient({
        space: process.env.REACT_APP_SPACE_ID,
        environment: 'dev', // testing mode
        accessToken: process.env.REACT_APP_PREVIEW_TOKEN_DEV,
        host: 'preview.contentful.com'
    });
} else {
    // Use Standard API (only shows published)
    client = createClient({
        space: process.env.REACT_APP_SPACE_ID,
        accessToken: process.env.REACT_APP_ACCESS_TOKEN
    });
}

export default client;
