import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

// Utils
import { Arrow, IconPlay } from '../../utils/svgIcons';
import { BorderAnimation } from '../../utils/borderAnimation';
import { ScaleWhenVisible } from '../../utils/scaleWhenVisible';
import { useVideoPlayer } from '../../hooks/ui/useVideoPlayer';

const scrollBtnAnimate = {
    visible: {
        y: ['70px', '0px'],
        transition: {
            delay: 2,
            duration: 0.8,
            yoyo: Infinity,
            type: 'spring',
            ease: 'easeInOut'
        }
    },
    hidden: {
        y: 0
    }
};

const FullVideo = ({ videoDesktop, index }) => {
    const [playState, setPlayState] = useState(false);
    const [ref, inView] = useInView({ threshold: [0.75] });
    const { playingId, onPlayingId } = useVideoPlayer();

    useEffect(() => {
        if (inView) {
            onPlayingId(videoDesktop);
        } else if (playingId === videoDesktop) {
            onPlayingId('');
        }
    }, [inView]);

    useEffect(() => {
        if (playingId !== videoDesktop) {
            setPlayState(false);
        } else {
            setPlayState(true);
        }
    }, [playingId]);

    const handlePlay = () => {
        onPlayingId(videoDesktop);
    };

    return (
        <div className="player-wrapper" ref={ref}>
            <ReactPlayer
                id={index}
                playing={playState}
                width="100%"
                className="react-player"
                height="100%"
                controls
                playsinline
                loop
                url={videoDesktop}
                onPlay={handlePlay}
            />
        </div>
    );
};

const VideoLoop = ({ videoDesktop, videoMobile }) => {
    const isMobile = window.innerWidth <= 500;
    return isMobile ? (
        <video
            className={'work-detail__video-topper'}
            preload="auto"
            playsInline
            loop
            autoPlay={true}
            muted={true}>
            <source src={videoMobile} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    ) : (
        <video
            className={'work-detail__video-topper'}
            preload="auto"
            playsInline
            loop
            autoPlay={true}
            muted={true}>
            <source src={videoDesktop} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

const Thumbnail = ({ thumbnailDesktop, thumbnailMobile }) => {
    return (
        <>
            <img
                className={'work-detail__video-thumbnail thumbnail-desktop'}
                src={thumbnailDesktop}
            />
            <img
                className={'work-detail__video-thumbnail thumbnail-mobile'}
                src={thumbnailMobile}
            />
        </>
    );
};

export default function Video({
    index,
    pageName,
    toElement,
    showScrollButton,
    fullVideoDesktop,
    loopVideoDesktop,
    loopVideoMobile,
    thumbnailDesktop,
    thumbnailMobile
}) {
    const { scroll } = useLocomotiveScroll();
    const [scrollY, setScrollY] = useState();
    const [showFullVideo, setShowFullVideo] = useState(false);

    const playFullVideo = () => {
        setShowFullVideo(!showFullVideo);
    };

    const scrollToElement = (target) => {
        scroll &&
            scroll.scrollTo(`#${target}`, {
                offset: -150,
                duration: 800,
                easing: [0.25, 0.0, 0.35, 1.0],
                disableLerp: false
            });
    };

    useEffect(() => {
        if (scroll) {
            scroll.on('scroll', (instance) => {
                const { y } = instance.scroll;
                setScrollY(y);
            });
        }
    }, [scroll, scrollY]);

    return (
        <div className={'work-detail__topper flex'}>
            <div className="overlay" />
            {showFullVideo ? (
                <FullVideo index={index} videoDesktop={fullVideoDesktop} />
            ) : (
                <>
                    {loopVideoDesktop ? (
                        <VideoLoop videoDesktop={loopVideoDesktop} videoMobile={loopVideoMobile} />
                    ) : (
                        <Thumbnail
                            thumbnailDesktop={thumbnailDesktop}
                            thumbnailMobile={thumbnailMobile}
                        />
                    )}
                    {fullVideoDesktop && (
                        <div className={'hidden-icon flex'}>
                            <button className={'play-btn flex top'} onClick={() => playFullVideo()}>
                                <BorderAnimation width={'105'} height={'105'} color={'#fff'} />
                                <IconPlay color={'#fff'} />
                            </button>
                        </div>
                    )}

                    {showScrollButton &&
                        (pageName === 'detail' ? (
                            <ScaleWhenVisible
                                delayTo={1.7}
                                delayFrom={1.8}
                                zIndex={1200}
                                position={'absolute'}
                                bottom={'0px'}>
                                <motion.div
                                    animate={{ display: 'flex' }}
                                    transition={{ delay: 2 }}
                                    className={'scroll-btn-animate'}>
                                    <motion.button
                                        variants={scrollBtnAnimate}
                                        animate={'visible'}
                                        initial={'hidden'}
                                        exit={'hidden'}
                                        className={'scroll-btn flex'}
                                        onClick={() => scrollToElement(toElement)}>
                                        <Arrow color={'#fff'} />
                                    </motion.button>
                                </motion.div>
                            </ScaleWhenVisible>
                        ) : (
                            <motion.button
                                variants={scrollBtnAnimate}
                                animate={'visible'}
                                initial={'hidden'}
                                exit={'hidden'}
                                className={'scroll-btn flex'}
                                onClick={() => scrollToElement(toElement)}>
                                <BorderAnimation width={'70'} height={'70'} color={'#fff'} />
                                <Arrow color={'#fff'} />
                            </motion.button>
                        ))}
                </>
            )}
        </div>
    );
}

Video.propTypes = {
    pageName: PropTypes.string,
    toElement: PropTypes.string,
    fullVideoDesktop: PropTypes.string,
    fullVideoMobile: PropTypes.string,
    loopVideoDesktop: PropTypes.string,
    loopVideoMobile: PropTypes.string,
    thumbnailDesktop: PropTypes.string,
    thumbnailMobile: PropTypes.string
};
