import useContentful from '../api/useContentful';

export default function useTeamPage() {
    const { data, isLoading } = useContentful('6Pm26CZRecVSMPTjGdRQ0k');

    const title = data?.title || '';
    const showFeaturedEmployee = data?.showFeaturedEmployee || null;
    const percentBlockSection = data?.percentBlockSection || [];
    const tipsListSection = data?.tipsListSection || [];
    const featuredEmployeeSection = data?.featuredEmployeeSection || [];
    const employeesListSection = data?.employeesListSection.filter((item) => item.fields) || [];

    return {
        title,
        showFeaturedEmployee,
        percentBlockSection,
        tipsListSection,
        featuredEmployeeSection,
        employeesListSection,
        isLoading
    };
}
