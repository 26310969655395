import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { useInView } from 'react-intersection-observer';

//components
import Button from '../../components/button';

// Hooks
import useNews from '../../hooks/ui/useNews';
import useGAEventsTrack from '../../hooks/utils/useGAEventsTrack';

// Utils
import { Navigator } from '../../utils/carouselControlls';
import { MoveWhenVisible } from '../../utils/moveWhenVisible';
import { ShowEachWhenVisible } from '../../utils/scaleEachWhenVisible';
import HoverEffect from '../../utils/hoverEffect';
import { NavContext } from '../../utils/navContext';

function NewsItem({ items }) {
    const GATracker = useGAEventsTrack('News Pages');
    const { pathname } = useLocation()

    const filteredNews = items?.filter(item => item.fields.slug.toLowerCase() !== pathname.toLowerCase().split("/").pop());

    return filteredNews.map((item, index) => {
        const { newsDetailItems, slug, url, image, title } = item.fields;
        return (
          newsDetailItems ? (
                <li key={index} className="keen-slider__slide">
                    <Link
                        to={`/news/${slug}`}
                        rel="noopener noreferrer"
                        onClick={(e) => GATracker(title)}>
                        <div className={'news-item'}>
                            <ShowEachWhenVisible
                                index={index}
                                translateFrom={'0%'}
                                translateTo={'-100%'}
                                position={'absolute'}
                                delayTo={0.3}
                                delayFrom={0.8}
                                zIndex={'1'}
                                background={'white'}
                            />
                            <HoverEffect>
                                <img
                                    src={image.fields.file.url}
                                    alt={image.fields.title}
                                />
                            </HoverEffect>
                            <h3>{title}</h3>
                        </div>
                    </Link>
                </li>
            )
            :
            (
              <li key={index} className="keen-slider__slide">
                  <a href={url}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    onClick={(e) => GATracker(title)}>
                      <div className={'news-item'}>
                          <ShowEachWhenVisible
                            index={index}
                            translateFrom={'0%'}
                            translateTo={'-100%'}
                            position={'absolute'}
                            delayTo={0.3}
                            delayFrom={0.8}
                            zIndex={'1'}
                            background={'white'}
                          />
                          <HoverEffect>
                              <img
                                src={image.fields.file.url}
                                alt={image.fields.title}
                              />
                          </HoverEffect>
                          <h3>{title}</h3>
                      </div>
                  </a>
              </li>
            )
        );
    });
}

function NewsView() {
    const { title, items } = useNews();
    const [options, setOptions] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider(options);
    const [refNav, inView] = useInView({ threshold: 0.3 });
    const { setIsInView } = useContext(NavContext);

    useEffect(() => {
        if (inView) {
            setIsInView('news');
        }
    }, [inView, setIsInView]);

    useEffect(() => {
        if (items.length) {
            setOptions({
                initial: 0,
                spacing: 10,
                duration: 800,
                loop: false,
                slidesPerView: 3,
                slideChanged(s) {
                    setCurrentSlide(s.details().relativeSlide);
                },
                breakpoints: {
                    '(max-width: 768px)': {
                        slidesPerView: 1.5
                    },
                    '(max-width: 481px)': {
                        slidesPerView: 1.26,
                        spacing: 20,
                    },
                    '(max-width: 415px)': {
                        slidesPerView: 1.2
                    }
                }
            });
            slider && slider.refresh();
        }
    }, [slider, items]);
    
    return (
        <section className={'news'} data-scroll-section ref={refNav}>
            <MoveWhenVisible fromX={0} toX={20} delayFrom={0.2} delayTo={0.2}>
                <h3 className={'news-title'} id={'news'}>
                    {title}
                </h3>
            </MoveWhenVisible>
            <ul ref={sliderRef} className="keen-slider" data-scroll>
                <NewsItem items={items} />
            </ul>
            {items && items.length >= 4
                ? slider && (
                      <>
                          <Navigator currentSlide={currentSlide} slider={slider} color={'#fff'} />
                      </>
                  )
                : null}

            <Button url={'/news'} text={'View All'} mod={'news-list'} />
        </section>
    );
}

export default NewsView;
