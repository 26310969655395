import useContentful from '../api/useContentful';

export default function useHomeMeta() {
    const { data, isLoading } = useContentful('7eVLVQJHbnX0rpNImx27w2');

    const title = data?.oggTitle || '';
    const description = data?.oggDescription || '';
    const image = data?.oggImage.fields.file || '';

    return {
        title,
        image,
        description,
        isLoading
    };
}
