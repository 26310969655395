import React, { useContext, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { AnimatePresence, motion } from 'framer-motion';

// Utils
import { Arrow } from '../../utils/svgIcons';
import { Navigator } from '../../utils/carouselControlls';
import { BorderAnimation } from '../../utils/borderAnimation';

// Animation
import {
    aboutAnimate,
    backBtnAnimate,
    bgImageAnimate,
    descriptionAnimate,
    loaderAnimate,
    modalAnimate
} from './animate';
import ProgressLoader from '../progressLoader';
import { NavContext } from '../../utils/navContext';
import ReadMore from '../readMore/readMore';

function Modal({ onCycle, employee, index, isVisible, link, activeChips }) {
    const { setIsModalShow } = useContext(NavContext);
    const location = useLocation();
    
    console.log(employee)

    const [sliderState, setSliderState] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider({
        initial: index,
        spacing: 0,
        duration: 1280,
        loop: false,
        slidesPerView: 1,
        move: (s) => {
            setSliderState(s.details());
        },
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        }
    });

    const itemsRef = useRef([]);
    itemsRef.current = [];

    const slideValues = () => {
        const values = [];
        const sizes = [];

        const value = sliderState.absoluteSlide;
        const size = sliderState.size;
        values.push(value + 1);
        sizes.push(size);

        return [`${values} / ${sizes}`];
    };

    function positionStyle(idx) {
        if (!sliderState) return {};
        const position = sliderState.positions[idx];
        const x = sliderState.widthOrHeight * position.distance;
        const scale_size = 0.7;
        const scale = 1 - (scale_size - scale_size * position.portion);
        return {
            transform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
            WebkitTransform: `translate3d(${x}px, 0px, 0px) scale(${scale})`
        };
    }

    const hideModal = (onCycle) => {
        // need for header animation
        setIsModalShow(true);
        // Hide modal with animation
        onCycle(onCycle);
    };

    const handleScrollOnMobile = (idx) => {
        itemsRef.current[idx].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };

    return (
        !isVisible &&  (
            <motion.div
                variants={modalAnimate}
                initial={'hidden'}
                animate={'initial'}
                exit={'exit'}>
                <motion.div
                    className={'loader-wrapper'}
                    variants={loaderAnimate}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    <AnimatePresence>
                        <ProgressLoader delayLogo={0.7} />
                    </AnimatePresence>
                </motion.div>
                <div className={'modal'}>
                    <div className={'team-detail'}>
                        <div ref={sliderRef} className={'keen-slider'}>
                            {employee.map((item, idx) => {
                                return (
                                    item.fields && (
                                        <div
                                            className={'keen-slider__slide team-detail_about'}
                                            key={idx}>
                                            <div
                                                className={'animate-wrapper'}
                                                style={positionStyle(idx)}>
                                                <motion.div
                                                    variants={backBtnAnimate}
                                                    initial={'hidden'}
                                                    animate={'initial'}
                                                    exit={'exit'}>
                                                    <Link
                                                        className={'team-detail_back-arrow'}
                                                        to={link}
                                                        onClick={() => hideModal(onCycle)}>
                                                        <Arrow color={'#db0400'} />
                                                        <p>back</p>
                                                    </Link>
                                                </motion.div>
                                                <div className={'img-block'}>
                                                    <div className={'img-block_wrapper'}>
                                                        <button
                                                            className={'scroll-btn flex position'}
                                                            onClick={() =>
                                                                handleScrollOnMobile(idx)
                                                            }>
                                                            <BorderAnimation
                                                                width={'50'}
                                                                height={'50'}
                                                                color={'#fff'}
                                                            />
                                                            <Arrow color={'#fff'} />
                                                        </button>
                                                        <motion.img
                                                            variants={bgImageAnimate}
                                                            initial="hidden"
                                                            animate="initial"
                                                            exit="exit"
                                                            src={
                                                                item.fields.backgroundImage.fields
                                                                    .file.url
                                                            }
                                                            alt={
                                                                item.fields.backgroundImage.fields
                                                                    .title
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <motion.div
                                                    initial="hidden"
                                                    animate="initial"
                                                    variants={descriptionAnimate}
                                                    exit="exit"
                                                    className={'description-block'}>
                                                    <motion.div
                                                        variants={aboutAnimate}
                                                        initial="hidden"
                                                        animate="visible">
                                                        {slider && (
                                                            <>
                                                                {employee.length <= 1 ? null : (
                                                                    <div className={'modal-nav'}>
                                                                        <Navigator
                                                                            currentSlide={
                                                                                currentSlide
                                                                            }
                                                                            slider={slider}
                                                                            color={'#9c9c9c'}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {sliderState
                                                            ? slideValues().map((value, key) => {
                                                                  return (
                                                                      <div
                                                                          key={key}
                                                                          className={'slider-size'}>
                                                                          {value}
                                                                      </div>
                                                                  );
                                                              })
                                                            : null}
                                                        {location.pathname === '/team' ? (
                                                            <div className={'employer-tags'}>
                                                                {activeChips.map((item, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={
                                                                                'employer-tags_tag'
                                                                            }>
                                                                            {item}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            className={'description-block_line'}
                                                            ref={(el) =>
                                                                (itemsRef.current[idx] = el)
                                                            }></div>
                                                        <p className={'description-block_position'}>
                                                            {item.fields.position}
                                                        </p>
                                                        <h1 className={'description-block_title'}>
                                                            {item.fields.title}
                                                        </h1>

                                                        <div className={'read-wrapper'}>
                                                            <ReadMore>
                                                                {item.fields.description}
                                                            </ReadMore>
                                                        </div>

                                                        {location.pathname === '/team' ? (
                                                            <div
                                                                className={
                                                                    'description-block_links'
                                                                }>
                                                                {item.fields?.linksTitle  ?
                                                                    <h2>{item.fields?.linksTitle}</h2>
                                                                  :
                                                                  null
                                                                }
                                                                {item.fields?.links?.length ?
                                                                <ul
                                                                    className={
                                                                        'description-block_links-list flex'
                                                                    }>
                                                                    {item.fields?.links?.map(
                                                                        (item, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        href={
                                                                                            item
                                                                                                .fields
                                                                                                .url
                                                                                        }>
                                                                                        {
                                                                                            item
                                                                                                .fields
                                                                                                .title
                                                                                        }
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                                  : null
                                                                }
                                                            </div>
                                                        ) : null}

                                                        <div className={'description-block_works'}>
                                                            {item.fields.interestsTitle &&
                                                                <h2>{item.fields.interestsTitle}</h2>
                                                            }
                                                            {item.fields.works.map(
                                                                (work, index) => {
                                                                    return (
                                                                        <div
                                                                            className={'works-item'}
                                                                            key={index}>
                                                                            <div
                                                                                className={
                                                                                    'works-item_img-block'
                                                                                }>
                                                                                <img
                                                                                    src={
                                                                                        work.fields
                                                                                            .image
                                                                                            .fields
                                                                                            .file
                                                                                            .url
                                                                                    }
                                                                                    alt={
                                                                                        work.fields
                                                                                            .image
                                                                                            .fields
                                                                                            .title
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <p
                                                                                className={
                                                                                    'works-item_title'
                                                                                }>
                                                                                {
                                                                                    work.fields
                                                                                        .description
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </motion.div>
                                                </motion.div>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    );
}

export default Modal;
