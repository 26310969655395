import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimateSharedLayout } from 'framer-motion';

// Hooks
import useHeader from '../../hooks/ui/useHeader';

// Utils
import { Ten35Logo } from '../../utils/svgIcons';
import { NavContext } from '../../utils/navContext';

// Assets
import bgMenu from '../../assets/images/bg-menu.png';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

let redLineAnimate = {
    visible: {
        opacity: 1,
        width: 229,
        height: 6,
        transition: { duration: 0.8 }
    },
    hidden: {
        opacity: 0,
        width: 0
    }
};

const logoAnimate = {
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.8,
            duration: 0.8
        }
    },
    hidden: {
        opacity: 0,
        x: 10
    }
};

const navAnimate = {
    expanded: {
        width: '100%',
        transition: {
            duration: 0.35,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    collapsed: {
        width: '0%'
    }
};

function Header() {
    const location = useLocation();
    const { links, socialItems } = useHeader();
    const { scroll } = useLocomotiveScroll();

    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [hideMenu, setHideMenu] = useState(false);

    const { setIsInView, isModalShow } = useContext(NavContext);

    const scrollToElement = (target) => {
        if(target === 'contact') {
            scroll &&
            scroll.scrollTo(`#${target}`, {
                offset: -130,
                duration: 800,
                easing: [0.25, 0.0, 0.35, 1.0],
                disableLerp: false
            });
        }
        
    };

    const stickyHeaderScroll = (args) => {
        const { y } = args;
        if (y > 0) {
            setScrolled(true);
        } else {
            setIsInView('');
            setScrolled(false);
        }
    };

    const mobHeaderScroll = () => {
        if (window.scrollY === 0) {
            setHideMenu(false);
        } else if (window.scrollY > 90) {
            setHideMenu(true);
        } else {
            setHideMenu(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', mobHeaderScroll);
        const timer = setTimeout(() => {
            if (window.scrollY > 0) {
                setHideMenu(false);
            } else {
                setHideMenu(false);
            }
        }, 950);

        return () => {
            window.removeEventListener('scroll', mobHeaderScroll);
            clearTimeout(timer);
        };
    }, [window.scrollY]);

    useEffect(() => {
        if (scroll) {
            scroll.on('scroll', (args) => stickyHeaderScroll(args.scroll));
        }
    }, [scroll]);

    return (
        <div className={isModalShow ? 'header-wrapper animated' : 'header-wrapper'}>
            <header
                className={`${scrolled ? 'header scrolled' : 'header'} ${hideMenu ? 'hide' : ''}`}>
                <nav className={'header-nav'}>
                    <div className={'header-logo'}>
                        <motion.div
                            className={'red-logo-line'}
                            variants={redLineAnimate}
                            animate={'visible'}
                            initial={'hidden'}
                            exit={'hidden'}
                        />
                        <motion.div
                            variants={logoAnimate}
                            animate={'visible'}
                            initial={'hidden'}
                            exit={'hidden'}>
                            <Link to={'/'} onClick={() => scrollToElement('hero')}>
                                <Ten35Logo color={'#ffffff'} />
                            </Link>
                        </motion.div>
                    </div>

                    <AnimateSharedLayout>
                        <motion.ul>
                            {links.map((link, index) => {
                                const currentLink = link.fields.linkName;
                                const matchPath = `${'/' + currentLink.toLowerCase()}`;
                                return (
                                    <motion.li
                                        key={index}
                                        variants={{
                                            visible: (index) => ({
                                                opacity: 1,
                                                x: 0,
                                                transition: {
                                                    duration: 1.2,
                                                    delay: index * 0.15
                                                }
                                            }),
                                            hidden: (index) => ({
                                                opacity: 0,
                                                x: -10 * index
                                            })
                                        }}
                                        custom={index}
                                        animate={'visible'}
                                        initial={'hidden'}
                                        exit={'hidden'}>
                                        <Link
                                            to={`${
                                                currentLink === 'contact' ? '#' : '/' + currentLink
                                            }`}
                                            className={`${
                                                location.pathname === matchPath ? 'active' : ''
                                            }`}
                                            onClick={() => scrollToElement(currentLink)}>
                                            {currentLink}
                                            {location.pathname === matchPath ? (
                                                <div className="underbar" />
                                            ) : (
                                                ''
                                            )}
                                        </Link>
                                    </motion.li>
                                );
                            })}
                        </motion.ul>
                    </AnimateSharedLayout>
                </nav>

                <nav className={`burger_menu ${isOpen ? 'fixed' : ''} ${hideMenu ? 'hide' : ''}`}>
                    <div className={'header-logo'}>
                        <Link to={'/'} onClick={() => scrollToElement('hero')}>
                            <Ten35Logo color={'#ffffff'} />
                        </Link>
                    </div>
                    <div
                        className={`burger_menu-icon ${isOpen ? 'open' : ''}`}
                        onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div
                        className={'burger_menu-nav'}
                        style={{ backgroundImage: `url(${bgMenu})` }}>
                        <ul className={'nav-list'}>
                            {links.map((link, index) => {
                                const currentLink = link.fields.linkName;
                                const matchPath = `${'/' + currentLink.toLowerCase()}`;
                                return (
                                    <li key={index} className={'nav-list_item'}>
                                        <Link
                                            to={`${
                                                currentLink === 'contact' ? '#' : '/' + currentLink
                                            }`}
                                            className={`${
                                                location.pathname === matchPath
                                                    ? 'active nav-list_link'
                                                    : 'nav-list_link'
                                            }`}
                                            onClick={() => {
                                                setIsOpen(!isOpen);
                                                scrollToElement(currentLink);
                                            }}>
                                            {currentLink}
                                            {location.pathname === matchPath ? (
                                                <div className="underbar" />
                                            ) : (
                                                ''
                                            )}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className={'burger_menu-social'}>
                            <ul className={'social-nav'}>
                                {socialItems.map((item, index) => (
                                    <li key={index} className={'social-nav_item'}>
                                        <a
                                            href={item.fields.link}
                                            target="_blank"
                                            className={'social-nav_link'}
                                            rel="noopener noreferrer">
                                            <img
                                                src={item.fields.image.fields.file.url}
                                                alt={item.fields.image.fields.title}
                                            />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Header;
