import React from 'react';

function ArrowLeft({ disabled, onClick, color }) {
    const disabeld = disabled ? ' arrow--disabled' : '';
    return (
        <svg
            onClick={onClick}
            className={'arrow arrow--left' + disabeld}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 29 19">
            <g fill={color} fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 7.571H26V10.571H0z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11)"
                                    />
                                    <path
                                        d="M16.317 4.164H29.072000000000003V7.164H16.317z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(45 22.695 5.664)"
                                    />
                                    <path
                                        d="M16.317 11.069H29.072000000000003V14.069H16.317z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(-45 22.695 12.569)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function ArrowRight({ disabled, onClick, color }) {
    const disabeld = disabled ? ' arrow--disabled' : '';
    return (
        <svg
            onClick={onClick}
            className={'arrow arrow--right' + disabeld}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 29 19">
            <g fill={color} fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M0 7.571H26V10.571H0z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11)"
                                    />
                                    <path
                                        d="M16.317 4.164H29.072000000000003V7.164H16.317z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(45 22.695 5.664)"
                                    />
                                    <path
                                        d="M16.317 11.069H29.072000000000003V14.069H16.317z"
                                        transform="translate(-1311 -7004) translate(0 1884.5) translate(100 4602) translate(1 507) translate(1210 11) rotate(-45 22.695 12.569)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export const Navigator = ({ currentSlide, slider, color }) => {
    return (
        <div className={'arrows'}>
            <div className={'arrow-wrapper arrow-wrapper-left'}>
                <ArrowLeft
                    onClick={(e) => e.stopPropagation() || slider.prev()}
                    disabled={currentSlide === 0}
                    color={color}
                />
            </div>
            <div className={'arrow-wrapper arrow-wrapper-right'}>
                <ArrowRight
                    onClick={(e) => e.stopPropagation() || slider.next()}
                    disabled={currentSlide === slider.details().size - 1}
                    color={color}
                />
            </div>
        </div>
    );
};

export const Dots = ({ currentSlide, slider }) => {
    return (
        <div className="dots">
            {[...Array(slider.details().size).keys()].map((idx) => {
                return (
                    <button
                        key={idx}
                        onClick={() => {
                            slider.moveToSlideRelative(idx);
                        }}
                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                    />
                );
            })}
        </div>
    );
};
