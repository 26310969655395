import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const HEADING_1 = ({ children }) => <h1 className="h1">{children}</h1>;
const HEADING_2 = ({ children }) => <h2 className="h2">{children}</h2>;
const HEADING_3 = ({ children }) => <h3 className="h3">{children}</h3>;
const HEADING_6 = ({ children }) => <h6 className="h6">{children}</h6>;
const QUOTE = ({ children }) => <blockquote className="blockquote">{children}</blockquote>;

const renderOptions = {
    renderNode: {
        [BLOCKS.QUOTE]: (node, children) => {
            return <QUOTE>{children}</QUOTE>;
        },
        [BLOCKS.HEADING_1]: (node, children) => {
            return <HEADING_1>{children}</HEADING_1>;
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return <HEADING_2>{children}</HEADING_2>;
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return <HEADING_3>{children}</HEADING_3>;
        },
        [BLOCKS.HEADING_6]: (node, children) => {
            return <HEADING_6>{children}</HEADING_6>;
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const assetType = node.data.target.fields.file.contentType;
            const contentGroup = assetType.split('/')[0];

            switch (contentGroup) {
                case 'video':
                    return (
                        <video width="100%" height="100%" controls className={'richText-video'}>
                            <source src={node.data.target.fields.file.url} type="video/mp4" />
                        </video>
                    );
                case 'image':
                    return (
                        <img
                            className={'richText-img'}
                            src={node.data.target.fields.file.url}
                            alt={node.data.target.fields.title}
                        />
                    );
                default:
                    return null;
            }
        }
    }
};

export default function useRichText() {
    const parseBodyText = (body) => {
        const parsedText = documentToReactComponents(body, renderOptions);

        return parsedText;
    };

    return {
        parseBodyText
    };
}
