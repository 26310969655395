import React from 'react';

export const BorderAnimation = ({ width, height, color }) => {
    const styleAnim = {
        width: width + 'px',
        height: height + 'px',
        borderColor: color
    };

    return <div className={'btn-border-anim btn-border-anim_on'} style={styleAnim}></div>;
};
