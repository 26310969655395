import React from 'react';
import { Link } from 'react-router-dom';

function Button({ url, text, mod }) {
    return (
        <Link to={url} className={`btn-route btn-route_${mod}`}>
            {text}
        </Link>
    );
}

export default Button;
