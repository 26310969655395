export const aboutAnimate = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 1.9, duration: 0.6 }
    },
    hidden: {
        opacity: 0,
        y: 100,
        transition: { delay: 1.9, duration: 0.6 }
    }
};

export const loaderAnimate = {
    visible: {
        opacity: [1, 0.9],
        width: ['100%', '0%'],
        clipPath: [
            'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            'polygon(0 0, 100% 0, 42% 100%, 0 100%)'
        ],
        transition: { delay: 1.5, duration: 0.8 }
    },
    // exit: {
    //     opacity: [1, 0.9],
    //     width: ['0%', '100%'],
    //     clipPath: [
    //         'polygon(0 0, 100% 0, 42% 100%, 0 100%)',
    //         'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
    //     ],
    // },
    hidden: {
        opacity: 0
    }
};

export const modalAnimate = {
    initial: {
        opacity: 1
    },
    hidden: {
        opacity: 0
    },
    exit: {
        opacity: 0,
        transition: { delay: 0.5, easeOut: [0.17, 0.67, 0.83, 0.67] }
    }
};

export const backBtnAnimate = {
    initial: {
        opacity: 1,
        x: 0,
        transition: { delay: 1.9, duration: 0.7 }
    },
    hidden: {
        opacity: 0,
        x: 20
    },
    exit: {
        opacity: 0,
        x: 20
    }
};

export const bgImageAnimate = {
    initial: {
        opacity: 1,
        transition: {
            delay: 1.8,
            duration: 0.5
        }
    },
    hidden: {
        opacity: 0
    },
    exit: {
        opacity: 0
    }
};

export const descriptionAnimate = {
    initial: {
        opacity: 1,
        transition: { delay: 0.8, duration: 0.8 }
    },
    hidden: {
        opacity: 0
    },
    exit: {
        opacity: 0
    }
};
