import React from 'react';
import { useMotionValue, useTransform, motion } from 'framer-motion';

const HoverEffect = ({ children }) => {
    const x = useMotionValue(100);
    const y = useMotionValue(100);

    const rotateX = useTransform(y, [0, 200], [25, -25]);
    const rotateY = useTransform(x, [0, 200], [-25, 25]);

    function handleMouse(event) {
        const rect = event.currentTarget.getBoundingClientRect();

        x.set(event.clientX - rect.left);
        y.set(event.clientY - rect.top);
    }

    const onLeave = () => {
        x.set(100);
        y.set(100);
    };

    return (
        <motion.div style={{ perspective: 600 }} onMouseMove={handleMouse} onPointerLeave={onLeave}>
            <motion.div
                style={{
                    rotateX: rotateX,
                    rotateY: rotateY,
                    transition: 'all .4s ease'
                }}>
                {children}
            </motion.div>
        </motion.div>
    );
};

export default HoverEffect;
